import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Folks, I am <span className="purple">Rishikesh Chandra </span>
            <br />
            With 9+ years in software, I've seen tech evolve from simple to complex. Starting in Mumbai, now CTO in Tricity for a multinational. I love crafting robust architectures and apps with my team.
            <br />
            I've successfully built and nurtured tech departments from scratch. Skilled in streamlining development processes, I make them production-ready and seamlessly integrated within product teams. My expertise extends to meeting enterprise-level demands.
            <br />
            I thrive on challenges, both professionally and personally. Clients and colleagues rely on me for simplifying complex issues and delivering practical solutions.
            <br />
            I break down big tasks into smaller, manageable ones, tackling each with precision and passion. This approach not only makes the process enjoyable for me but also easy to understand for others.
            
            In essence, I am not just a CTO; I am a transformative force, poised to elevate your technological endeavors to unprecedented heights.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
