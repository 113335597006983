import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/self.jpeg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter
} from "react-icons/ai";
import { FaLinkedinIn, FaStackOverflow } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={9} className="home-about-description">
            <h1 style={{ fontSize: "2.9em" }}>
            Professional Prelude: A Brief<span className="purple"> INTRODUCTION </span>
            </h1>
            <p className="home-about-body">
            As the CTO of <a href="https://intelligaia.com/">Intelligaia</a> with over nine years of experience in software engineering, I specialize in developing digital solutions across various industries. Leading our technology team, I make critical decisions on platforms, architecture, and design while overseeing product development and deployment.
            <br />
            With a background in Computer Engineering from the University of Pune, I've helped numerous businesses establish and optimize their technology departments. I thrive on tackling challenges, breaking down complex tasks into manageable components, and fostering innovation within my team. Passionate about software architecture and emerging technologies like AI and Web3, I'm dedicated to driving excellence and efficiency in every project we undertake.
              <br />
              <br />My fluency in classic languages like 
              <i>
                <b className="purple"> JavaScript, Node.js, and Go </b>
              </i>
              allows me to navigate diverse tech landscapes seamlessly.
              <br />
            </p>
          </Col>
          <Col md={3} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid rounded-circle" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/rishirebel"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/irishirebel"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/rishikesh-chandra-b2113a59/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://stackoverflow.com/users/5644413/rishikesh-chandra"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaStackOverflow />
                </a>
              </li>
            </ul>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
